@import '../../style-guide/components/vue-universal-actionbox.less';
@import '../../style-guide/components/platform-switcher.less';
@import '../../style-guide/components/frequently-asked';

#banner {
	min-height: 700px;
	background-image: url('../../../i/page/secure-vpn/hero.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #6b625a;
	background-size: cover;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white !important;

	.container {
		@media @mobile {
			padding: 0;
		}
	}

	.hero-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 96px;

		@media @mobile {
			margin-bottom: 64px;
		}

		.headline {
			margin: 72px 0 56px;

			h1 {
				color: white !important;
				font-size: 56px;
				font-weight: 700;
				line-height: 64px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 32px;

				@media @desktop {
					max-width: 1128px;
				}

				html[lang='ru-ru'] &, html[lang*='de'] & {
					max-width: 95vw;
					padding: 0 10px;

					@media @tablet {
						font-size: 48px;
						line-height: 56px;
					}

					@media @mobile {
						font-size: 32px;
						line-height: 48px;
					}
				}

				img {
					margin-right: 0.5rem;
				}
			}

			.subheadline-text {
				font-size: 20px;
				font-weight: 400;
				line-height: 30px;
				letter-spacing: 0px;
				text-align: center;
				max-width: 85%;
				margin: 0 auto;

				@media @desktop {
					max-width: 1128px;
				}
			}
		}

		.hero-awards {
			display: flex;
			margin-bottom: 56px;

			.hero-award {
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 158px;

				&:nth-child(2) {
					margin: 0 32px;
				}

				img {
					margin-right: 0.5rem;
				}

				.hero-award-content {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0px;
					text-align: left;

					.hero-award-text {
						font-weight: 500;
					}
				}
			}
			.trustpilot-container {
				height: 45px;
				max-height: 45px;

				@media @desktop {
					width: 500px;
					max-width: 500px;
					height: 30px;
					max-height: 30px;
				}

				.trustpilot-widget {
					height: inherit;

					iframe {
						height: inherit;
					}
				}
			}
		}

		a.try-button {
			margin-top: 0;
			background-color: #008941;
			color: #fff;
			height: 80px;
			width: 268px;
			max-width: 268px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			text-decoration: none;
			margin: 0 0 32px;
			border-radius: 6px;
			padding: 11px 19px;

			&:hover {
				background: #005629;
			}

			span {
				font-size: 20px;
				font-weight: bold;
				height: auto;
			}
		}

		.buttons {
			.js-mac {
				html[class*=' js-mac'] & {
					@media @desktop {
						display: flex;
						gap: 32px;
					}
				}
			}

			.js-ios {
				margin-bottom: 2rem;

				.coming-soon {
					font-size: 48px;
					font-weight: 700;
				}
			}
		}

		.hero-hint a {
			color: #fff;
			border-bottom: 2px dashed #fff;
			text-decoration: none;
		}
	}

	#hero-features {
		display: flex;
		width: 80%;
		justify-content: space-between;
		flex-wrap: wrap;

		@media @mobile, @tablet {
			gap: 24px;
			margin-bottom: 3rem;
		}

		@media @mobile {
			justify-content: center;
		}

		.hero-feature {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-size: 15px;
			font-weight: 800;
			line-height: 24px;
			letter-spacing: 2.5px;
			text-align: left;
			max-width: 264px;
			width: 264px;

			@media @desktop {
				margin-bottom: 3rem;
			}

			img.hero-feature-icon {
				margin-right: 0.5rem;
			}

			.hero-feature-text {
				max-width: 200px;
				word-wrap: break-word;
			}
		}
	}
}

#intro {
	.container {
		padding-bottom: 0;

		.subh2 {
			@media @desktop {
				width: 896px;
				margin: auto;
			}
		}

		.intro-img-container {
			.intro-img {
				margin: 0;

				@media @desktop {
					width: 896px;
					height: 385px;
				}
			}
		}
	}
}

#comparison {
	background-color: transparent;

	.container {
		text-align: center;
		background-color: #f8f9fa;

		@media @desktop {
			padding: 75px 0;
			max-width: 1360px;
			margin: 0 auto;
		}

		@media only screen and (min-width: 768px) and (max-width: 1055px),
			only screen and (min-width: 1056px) {
			margin-bottom: 20px;
		}

		.avg-comparison-table {
			background-color: transparent;
			margin-top: 0;

			@media @mobile, @tablet {
				padding: 0;
				margin: 0;
			}

			@media @desktop {
				width: 1128px;
			}
		}

		table {
			tr {
				border-bottom: 1px solid #dfddeb;

				&:last-child {
					td {
						padding: 18px;
						font-size: 14px;
						line-height: 21px;
					}
				}

				th {
					h3 {
						font-size: 16px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0px;
						text-align: center;

						@media @mobile {
							font-size: 15px;
							line-height: 18px;
							padding: 0 5px;
						}
					}
				}

				td {
					padding: 18px;
					width: 356px;
					font-size: 14px;
					line-height: 21px;

					&:first-child {
						width: 493px;

						@media @mobile {
							max-width: 150px;
							text-wrap: wrap;
							padding: 18px 5px;
							word-wrap: break-word;
						}

						@media @tablet {
							max-width: 300px;
							text-wrap: wrap;
							padding: 18px 5px;
							word-wrap: break-word;
						}
					}

					.check-table-no {
						background-image: url('../../../i/ico/facelift/table-icon/16/cross-table-16.svg');
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						width: 20px;
						height: 20px;
						margin-top: -10px;
						margin-left: -10px;
						padding: 0;
						overflow: hidden;
						border: 0;
						filter: invert(50%) sepia(8%) saturate(880%) hue-rotate(187deg)
							brightness(99%) contrast(92%);
					}

					.check-table-yes {
						background-image: url('../../../i/ico/facelift/table-icon/16/check-table-16.svg');
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						width: 20px;
						height: 20px;
						margin-top: -10px;
						margin-left: -10px;
						padding: 0;
						overflow: hidden;
						border: 0;
						filter: invert(36%) sepia(82%) saturate(1074%) hue-rotate(186deg)
							brightness(90%) contrast(96%);
					}
				}
			}
		}
	}
}

.features {
	@media @mobile {
		margin: 4rem 0;
	}

	&.left-image {
		@media @desktop {
			padding-top: 88px;
		}

		&.last-feature {
			@media @desktop {
				padding-top: 0;
				padding-bottom: 88px;
			}
		}

		.row {
			@media @mobile {
				flex-direction: column-reverse;
			}
		}
	}

	&.right-image {
		@media @desktop {
			padding-top: 88px;
			padding-bottom: 88px;
		}

		@media only screen and (min-width: 768px) and (max-width: 1055px),
			only screen and (min-width: 1056px) {
			.col.col-md-6 {
				width: 816px;
			}
		}
	}

	.row {
		// padding: 88px 0px 88px 116px;
		gap: 96px;
		max-width: 1360px;
		align-items: center;

		@media @desktop {
			height: auto;
			// min-height: 724px;
		}

		@media @mobile {
			gap: 32px;
			height: auto;
		}

		img {
			@media only screen and (min-width: 768px) and (max-width: 1055px),
				only screen and (min-width: 1056px) {
				height: auto;
				min-width: auto;
			}

			&.img-security {
				@media @desktop {
					height: 548px;
					min-width: 548px;
				}
			}
			&.img-illu,
			&.img-browse,
			&.img-leaks {
				@media @desktop {
					height: 533px;
					min-width: 647px;
				}
			}
		}

		p {
			margin-bottom: 0;

			a {
				color: #4b5563;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.content {
			@media @desktop {
				width: 432px;
			}

			a {
				span {
					border-bottom: 2px dashed #2a7de1;
					font-weight: 500;
				}
			}
		}

		a.collapsed {
			.show-all-features {
				margin-top: 15px;
				display: block;
			}

			.show-less-features {
				display: none;
			}
		}

		a[aria-expanded='true'] {
			.show-all-features {
				display: none;
			}

			.show-less-features {
				display: block !important;
			}
		}

		.button {
			text-align: left;
			display: flex;
			align-items: baseline;

			&.minus {
				align-items: center;
			}
		}

		.text-bold-li {
			font-weight: bold;
		}

		li {
			padding-bottom: 0;

			.list-item {
				p {
					margin-left: 48px;
				}

				&:before {
					position: relative;
					top: 45px;
					left: 0;
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					background: transparent
						url(../../../i/page/free-antivirus-download/check-oval.svg) center center
						no-repeat;
				}
			}

			p {
				margin-bottom: 0;
			}
		}
	}

	a.button.plus::after {
		content: url('../../../i/mobile-security-for-iphone-ipad/cross-icon.svg');
		margin-left: 16px;
		transform: rotate(135deg);
	}

	a.button.minus::after {
		content: url('../../../i/mobile-security-for-iphone-ipad/cross-icon.svg');
		margin-left: 16px;
		transform: rotate(90deg);
	}
}

#manage-privacy {
	.container {
		background-color: #f8f9fa;

		@media only screen and (min-width: 768px) and (max-width: 1055px),
			only screen and (min-width: 1056px) {
			padding-top: 20px;
		}

		@media @desktop {
			padding: 88px 0 0;
			margin: 0 auto;
			max-width: 1360px;
		}

		@media @mobile {
			padding-top: 20px;
		}

		.manage-privacy-container {
			text-align: center;

			.manage-privacy-top {
				color: #008941;
				text-transform: uppercase;
				margin-bottom: 16px;
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				letter-spacing: 0px;
			}

			h2 {
				margin-bottom: 92px;
			}

			img {
				@media @desktop {
					width: 896px;
				}
			}
		}
	}
}

#bottom-dwn {
	background-color: #ffffff;

	.container {
		background-color: #292e38;
		color: #ffffff;
		@media @desktop {
			padding: 88px 0 88px 0;
			margin: 0 auto;
			max-width: 1360px;
		}

		@media @mobile, @tablet {
			padding-top: 20px;
		}

		.product-containter {
			.product-top {
				display: flex;
				gap: 16px;
				justify-content: center;
				margin-bottom: 16px;

				.product-logo {
					width: 50px;
					height: 50px;
					padding: 10px;
					border-radius: 4px;
					background-color: #ffffff;

					@media @mobile {
						min-height: 50px;
						min-width: 50px;
					}

					img {
						padding: 0;
					}
				}

				h2 {
					color: #ffffff;
					padding: 0;
				}
			}

			p.subh {
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0px;
				text-align: center;
				color: #ffffff;
			}

			.buttons.inverse {
				a.try-button {
					margin-top: 0;
					background-color: #fff;
					color: #008941;
					height: 80px;
					width: 268px;
					max-width: 268px;
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					text-decoration: none;
					margin: 0 0 32px;
					border-radius: 6px;
					padding: 11px 19px;

					&:hover {
						background: #ffffff;
					}

					span {
						font-size: 20px;
						font-weight: bold;
						height: auto;
					}
				}

				.js-mac {
					html[class*=' js-mac'] & {
						display: flex;
						justify-content: center;

						@media @desktop {
							gap: 32px;
						}

						@media @mobile, @tablet {
							flex-direction: column;

							div {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}

				.js-ios {
					margin-bottom: 2rem;

					.coming-soon {
						font-size: 48px;
						font-weight: 700;
					}
				}

				.js-pc {
					html[class*=' js-pc'] & {
						display: flex;
						justify-content: center;
					}
				}

				.js-android {
					html[class*=' js-android'] & {
						display: flex;
						justify-content: center;
					}
				}

				.js-android-market {
					html[class*=' js-android-market'] & {
						display: flex;
						justify-content: center;
					}
				}
			}

			.product-hint {
				a {
					border-bottom: 2px dashed #2276d9;

					&:hover {
						text-decoration: none;
						border-bottom: none;
					}
				}
			}
		}
	}
}

#faq {
	@media @tablet {
		margin-top: 6rem;
	}

	h2 {
		position: relative;
		z-index: 1;
		text-align: center;
		margin-bottom: 64px;
	}

	.container {
		padding-bottom: 96px;

		@media @mobile {
			padding-bottom: 0;
		}

		@media @desktop {
			padding: 0 0 88px 0;
			margin: 0 auto;
			max-width: 1360px;
		}

		.row {
			@media @desktop {
				margin: 0;
			}
		}

		& > .row > .background-gray {
			background-color: #f8f9fa;

			@media @desktop {
				padding: 88px 64px;
				min-width: 1200px;
			}

			@media @mobile {
				padding: 44px 0;
			}
		}
	}

	.faq-container {
		position: relative;
		z-index: 10;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
		background-color: #fff;
		padding-top: 15px;
		padding-bottom: 15px;

		.question {
			padding: 39px 128px 39px 48px;
			position: relative;
			cursor: pointer;

			&::after {
				position: absolute;
				content: '';
				display: inline-block;
				border-radius: 5px;
				border: 1px solid #d6dae0;
				background-color: transparent;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url('../../../i/ico/ico-plus-blue.svg');
				top: 24px;
				right: 32px;
				width: 56px;
				height: 56px;
			}

			&:hover:not(.expand)::after {
				background-color: #00b451;
				border-color: #00b451;
				background-image: url('../../../i/ico/ico-plus-white.svg');
			}

			&.expand {
				&::after {
					background-color: transparent;
					background-image: url('../../../i/ico/ico-close-green.svg');
				}

				h4 {
					color: #2276d9;
				}
			}
		}

		.answer {
			transition: max-height 0.3s ease-out;
			height: auto;
			max-height: 0;
			overflow: hidden;
			border-bottom: 1px solid #dbdfe5;
			text-align: left;
			padding: 0 40px;

			@media @desktop {
				padding: 0 128px 0 80px;
			}

			&:last-child {
				border-bottom: none;
			}

			ol {
				padding-inline-start: 40px;
			}

			a {
				color: #2276d9;

				&:hover {
					text-decoration: underline;
				}
			}

			ul {
				padding-left: 24px;

				li {
					padding-bottom: 0;
					list-style: disc;
				}
			}

			ol li {
				padding: 0;
			}
		}

		div.expand + div {
			max-height: 600px;

			@media @mobile {
				max-height: fit-content;
			}
		}
	}
}

/* blog-posts */
#blogposts {
	padding-top: 88px;
	padding-bottom: 88px;
	@media @mobile {
		padding-top: 64px;
		padding-bottom: 0;
	}

	.button.secondary > span {
		font-weight: 500;
	}

	.container {
		//overflow-x: hidden;
		padding-bottom: 60px;
		@media @mobile, @tablet {
			padding-bottom: 48px;
		}
	}

	.post {
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.row {
		&.blog {
			justify-content: center;
			@media @mobile, @tablet {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.title {
			h2 {
				@media @mobile, @tablet {
					font-size: 30px;
					line-height: 40px;
				}
			}
		}
	}

	.carousel-slider {
		.container {
			@media @mobile, @tablet {
				padding-top: 0;
				padding-right: 0;
				padding-left: 20px;
			}
		}
		.tns-nav {
			bottom: -60px;
			@media @mobile, @tablet {
				bottom: 0;
			}
		}
		.tiny-slider {
			.tns-inner:before {
				background-image: linear-gradient(
					to left,
					rgba(255, 255, 255, 0.01) 0,
					#ffffff 256px
				);
			}
			.tns-inner:after {
				background-image: linear-gradient(
					to right,
					rgba(255, 255, 255, 0.01) 0,
					#ffffff 256px
				);
			}
			.tns-item {
				width: 353px;
				@media @mobile, @tablet {
					margin-right: 20px;
					width: 256px;
				}
			}

			@media @mobile, @tablet {
				overflow: hidden;
				padding-bottom: 56px;
			}
		}
	}

	h2 {
		text-align: left;
		margin-bottom: 0;
		max-width: 664px;
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	img {
		width: 100%;
	}

	p {
		color: @Grey-1;
		text-decoration: none;
		font-size: 14px;
	}

	.text-carousel {
		padding: 20px 20px;
		@media @mobile, @tablet {
			padding: 10px 10px;
		}
	}

	a {
		&:hover {
			text-decoration: none;
		}
	}

	.link-all {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-bottom: 0;

		a.button {
			display: flex;
			justify-content: flex-end;
		}
	}

	a.button.secondary {
		border: none;
	}

	a.button.secondary span {
		border-bottom: 2px dashed #2276d9;
	}

	.button.secondary > span {
		font-weight: 500;
	}

	.slider-controls {
		img {
			margin-bottom: 32px;
		}
	}

	.item-container {
		width: 289px;
		height: 100%;
		border: 1px solid #e8e8e8;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media @tablet, @mobile {
			width: 240px;
		}
	}

	h4 {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid #dbdfe5;
		font-weight: 700;
		color: #1e222a;
		height: 61px;
	}

	p {
		color: #4b5563;
		text-decoration: none;
		font-size: 14px;
	}

	.blog-title {
		@media @mobile {
			max-height: 61px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1055px),
	only screen and (min-width: 1056px) {
	.row {
		margin: 0;
	}
}

.row::after {
	content: none;
}

/* Modal */
.modal-open {
    position: relative; // fix: modal backdrop covered page only partially
}

.modal-lg {
    @media @tablet, @desktop {
        width: 90%;
        max-width: 1060px;
    }
}
.modal-backdrop {
	height: 100%;
    z-index: 999;
}

.modal-header {
	border: none;
}

.modal-dialog {
	@media (max-width: 635px) {
		width: auto;
		position: relative;
		margin: 10px;
	}

	display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	margin: auto;

	.modal-content {
		border-radius: 10px;
	}
}

.modal-body {
	padding: 80px 104px;
	text-align: center;

    @media @mobile {
        padding: 40px 20px;
    }

	h3 {
		@media @desktop {
			font-size: 34px;
			line-height: 44px;
			letter-spacing: 0px;
			margin-bottom: 0;
		}
	}

	.body-3 {
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0px;
	}

	ol {
		margin-left: 25px;

        > li::marker {
            font-weight: bold;
        }
	}

    ul {
        li {
            padding-bottom: 0;
        }
    }

    .wrapper {
        margin-bottom: 48px;

        h4 {
            font-size: 16px;
        }

        table {
            border-collapse: separate;
            width: 100%;
            margin-bottom: 24px;

            th {
                padding: 24px;

                @media only screen and (max-width: 767px){
                    display: block;
                    width: 33%;
                    float: left;
                    padding: 0;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) { 
                        background-color: @Grey-6; 
                    }

                    td:first-child {
                        padding-left: 8px;
                        text-align: left;

                        @media only screen and (max-width: 767px){
                            display: block;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
    
                td {
                    padding: 15px 0;
                    border-bottom: 1px solid @gray-13;
                    text-align: center;

                    @media only screen and (max-width: 767px){
                        display: block;
                        width: 33%;
                        float: left;
                    }                    
    
                }
            }
        }

        .table-note {
            color: #9AA3B5;
            font-size: 16px;
        }
    }

	.modal-buttons {
		display: flex;
		gap: 1rem;
		justify-content: center;
		margin-top: 2rem;

		@media @mobile {
			flex-direction: column;
			align-items: center;
		}

		a.try-button {
			margin-top: 0;
			background-color: #008941;
			color: #fff;
			min-height: 48px;
			width: 130px;
			min-width: 130px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			text-decoration: none;
			margin: 0 0 16px;
			border-radius: 6px;
			padding: 11px 19px;

			&:hover {
				background: #005629;
			}

			span {
				font-size: 14px;
				line-height: 16px;
				font-weight: bold;
				height: auto;
			}

			&.outline {
				color: #008941;
				background-color: transparent;
				border: 1px solid #008941;

				&:hover {
					background-color: #00783B;
					color: #FFF;
				}
			}
		}

		.btn-hint {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0px;
			margin-bottom: 0;
		}
	}
}

.modal-close {
    position: absolute;
    z-index: 2;
    cursor: pointer;

    border-radius: 5px;
    border: 1px solid @Grey-4;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../i/page/virus-protection-guarantee/modal-close.svg);

    @media @tablet, @desktop {
        top: 40px;
        right: 32px;
        width: 56px;
        height: 56px;
    }
    @media @mobile {
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
    }


    &:hover {
        background-color: @Green;
        border-color: transparent;
        opacity: 1;
        background-image: url(../../../i/page/virus-protection-guarantee/modal-close-hover.svg);
    }
}
/* /Modal */
